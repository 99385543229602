import React from "react"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default () => (
  <Layout>
    <HeadMeta pageTitle={"Project Overview"} />
    <PageHeader>Project Overview</PageHeader>
    <Breadcrumb
      route={[
        { name: "Parliamentary Papers", href: "/" },
        { name: "Project Overview", href: "/project-overview/" },
      ]}
    />
    <p>
      Guide to the papers of members of the New South Wales Parliament,
      1856-2006 in the Mitchell Library, State Library of New South Wales is
      published to coincide with the Sesquicentenary of Responsible Government
      in New South Wales 1856-2006. The Project is funded by a grant from the
      Sesquicentenary of Responsible Government in New South Wales 1856-2006
      Committee.
    </p>
    <p>
      The Guide is intended to give an overview of manuscript collections of
      members of the New South Wales Parliament held in the Mitchell Library and
      to include images of members from the Library's pictures collection. The
      Guide includes biographical information, a list of collections, and images
      of the member where available. The Guide is linked to the Library's
      pictures and manuscripts catalogue PICMAN. A detailed description of the
      scope and contents of each collection is accessible through the link.
    </p>
    <p>
      The Guide also provides general guidelines for accessing the collections
      in the Mitchell Library, State Library of New South Wales.
    </p>
  </Layout>
)
